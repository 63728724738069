var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"redirects__create"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('redirect',{attrs:{"title":"URL redirects","route":"website.redirects"}})],1),_c('v-col',{staticClass:"px-5 py-5",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Short link")]),_c('span',[_vm._v(" The original URL that you'd like to forward visitors from. ")]),_c('span',[_vm._v(" Usually, this is an old spanage the visitors can no longer access because the content was moved to a different URL or deleted. ")])]),_c('v-col',{staticClass:"px-5 py-5",attrs:{"cols":"12","md":"8","sm":"8"}},[_c('v-card',[_c('v-card-text',[_c('span',[_vm._v("Short link")]),_c('v-text-field',{attrs:{"placeholder":"e.g. /shop/shoes","error-messages":_vm.invalid && !_vm.$v.redirect.shortLink.required
                    ? _vm.$t('Short link is required')
                    : _vm.invalid && !_vm.$v.redirect.shortLink.domain
                    ? 'Short link invalid'
                    : ''},model:{value:(_vm.redirect.shortLink),callback:function ($$v) {_vm.$set(_vm.redirect, "shortLink", $$v)},expression:"redirect.shortLink"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-divider')],1),_c('v-col',{staticClass:"px-5 py-5",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Full Link")]),_c('span',[_vm._v(" The new URL that visitors should be forwarded to. ")]),_c('span',[_vm._v(" If you want to redirect your store's homepage, enter / (a forward slash). ")])]),_c('v-col',{staticClass:"px-5 py-5",attrs:{"cols":"12","md":"8","sm":"8"}},[_c('v-card',[_c('v-card-text',[_c('span',[_vm._v("Full Link")]),_c('v-text-field',{attrs:{"placeholder":"e.g. /shop/shoes","error-messages":_vm.invalid && !_vm.$v.redirect.fullLink.required
                    ? _vm.$t('Full link is required')
                    : _vm.invalid && !_vm.$v.redirect.fullLink.domain
                    ? 'Full link invalid'
                    : ''},model:{value:(_vm.redirect.fullLink),callback:function ($$v) {_vm.$set(_vm.redirect, "fullLink", $$v)},expression:"redirect.fullLink"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-divider'),_c('div',{staticClass:"mt-5 text-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"color":""},on:{"click":function($event){return _vm.$router.push({ name: 'website.redirects' })}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.isLoading},on:{"click":_vm.submit}},[_vm._v("Save redirect")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }